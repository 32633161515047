import {
  defineComponent, computed
} from '@vue/composition-api';

export default defineComponent({
  name: 'SvgIcon',
  props: {
    iconName: {
      type: String,
      value: ''
    }
  },
  setup(props, SetupContext) {
    const iconId = computed(() => `#icon-${props.iconName}`)

    function handler() {
      SetupContext.emit('click')
    }
    return {
      iconId,
      handler
    }
  }
})
