import {defineComponent, onDeactivated, Ref, ref} from '@vue/composition-api';
import SvgIcon from '@/components/svg-icon'
import {throttle} from 'lodash'
import {InfoItem} from './index.d'

export default defineComponent({
  name: 'Home',
  components: {SvgIcon},
  metaInfo: {
    meta: [
      {
        name: 'keywords',
        content: '豆牛、豆牛代卖、农业、卖货、一亩田、蔬菜配送、农产品批发、农产品采购、蔬菜行情、批发市场行情、水果行情、农业种植、代办、代卖、代采、批发市场、商机、稳赚、致富、农业政策、农业种养'
      }, {
        name: 'description',
        content: '豆牛代卖帮你卖货，足不出户就可以随时把货买到全国'
      }, {
        name: 'description',
        content: '专人服务帮你采购，帮你拿到低价货源，集中采购尽享优惠'
      }, {
        name: 'description',
        content: '代卖商免费入驻，享平台推荐货源、供应链金融等服务'
      }, {
        name: 'description',
        content: '全国70+农业批发市场，800+农产品品类，行情报价每日更新，价格波动、市场走货看一眼门清！'
      }, {
        name: 'description',
        content: '全国各地看货现场、装车发车、产品预订，做生意也能这样有趣！'
      }, {
        name: 'description',
        content: '完成任务，涨经验，升等级，更多福利陆续开放！'
      }, {
        name: 'description',
        content: '百万农人的致富平台！为农业助力！'
      }
    ]
  },
  setup() {
    const showButtonBtn: Ref<boolean> = ref(false)
    const showQrCode: Ref<boolean> = ref(false)
    const year = new Date().getFullYear()
    const openMenu: Ref<boolean> = ref(false)
    const serviceList: Ref<Array<InfoItem>> = ref([
      {
        img: 'https://img.yimutian.com/crm/5ec7beb2b8277368a207ddda01680168-165-165.png',
        title: '全国联动发货',
        desc: '全国大型批发市场，一站式对接服务，想发哪就发哪'
      },
      {
        img: 'https://img.yimutian.com/crm/5ec7beaeb8277368a207ddd601680168-165-165.png',
        title: '专人对接服务',
        desc: '果蔬市场资深顾问，提供1对1全流程服务'
      },
      {
        img: 'https://img.yimutian.com/crm/5ec7be7f2d211f581e07f5da01680168-165-165.png',
        title: '精准行情对比',
        desc: '每天人肉搜集市场行情，全国市场动态实时掌握'
      },
      {
        img: 'https://img.yimutian.com/crm/5ec7be97b8277368a207ddc601680168-165-165.png',
        title: '销售过程监管',
        desc: '市场蹲点监督销售，账单全部电子化，销售更透明'
      },
      {
        img: 'https://img.yimutian.com/crm/5ec7bea8b8277368a207ddd201680168-165-165.png',
        title: '货款安全有保障',
        desc: '平台结算货款，售完24小时内回款，货款及时更安全'
      }
    ])
    const cooperationList: Ref<InfoItem> = ref([
      {
        title: '报名, 等待专人联系',
        desc: '提交报名信息后，业务员将致电回访，确认货品详情，并介绍市场走货速度、行情、做货标准及相关费用成本等'
      },
      {
        title: '确认合作, 签订合同',
        desc: '综合对比市场并确认合作后，将签订《代卖合作合同》，以保证货主相关权益'
      },
      {
        title: '发车前, 核对代卖方案',
        desc: '核对业务员创建的线上《代卖方案》，后期可查询做货标准、销售及货款详情。'
      },
      {
        title: '发车后, 反馈司机车量信息',
        desc: '按标准卡组货完成后，将司机电话及车牌号等上传到《代卖方案》中或告知业务员'
      },
      {
        title: '车到市场, 确认验货结果',
        desc: '车到市场后，业务员会第一时间进行验货，并核对货品质量及重量，与货主确认无误后，再进入提前安排的档口'
      },
      {
        title: '销售中, 查看市场同步信息',
        desc: '业务员监督售卖，向货主提供每日的销售明细、行情变化、销售动态等，确保销售更透明。如遇降价或突发情况，会第一时间向货主发出申请并提供销售建议，获取决策认可'
      },
      {
        title: '销售完成, 确认销售账单',
        desc: '货品售完后，业务员与代卖商、货主核准账单，确认无误后，24小时内，由一亩田平台将货款结算到货主银行卡中'
      },
      {
        title: '合作完成, 反馈售卖评价',
        desc: '收到货款后，货主可针对本次服务进行评价反馈，若对服务存在异议，将有第三方部门介入调查。'
      }
    ])

    function reportHandler() {
      alert('开发人员正在开发中，敬请期待')
    }

    function showMenuHandler(show: boolean) {
      openMenu.value = show === undefined ? !openMenu.value : show

      document.body.addEventListener('click', () => {
        showMenuHandler(false)
      }, {once: true})
    }

    function qrCodeHandler(show: boolean) {
      showQrCode.value = show
    }

    const mainScroll = throttle(() => {
      const report: HTMLElement | null = document.querySelector('.report')

      if (report) {
        const top = report.offsetTop + report.clientHeight // 元素在页面中的位置

        showButtonBtn.value = document.documentElement.scrollTop >= top - 44
      }
    }, 15)

    window.addEventListener('scroll', mainScroll)

    onDeactivated(() => {
      window.removeEventListener('scroll', mainScroll)
    })

    return {
      showButtonBtn,
      showQrCode,
      year,
      serviceList,
      cooperationList,
      openMenu,
      reportHandler,
      showMenuHandler,
      qrCodeHandler
    }
  }
})
